import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format, isValid, parseISO } from 'date-fns';

import {
  FiSearch,
} from 'react-icons/fi';

import api from '../../services/api';

import {
  Container,
  CirculoCarregandoDiv,
  CirculoCarregando,
  Header,
  HeaderContent,
  Content,
  LinhaBuscar,
  InputData,
  BotaoPesquisarClientes,
  ListaCartoes,
  Cartao,
  CabecalhoCartao,
  ItensBox,
  ItemBox,
  ProdutoImagem,
  RodapeCartao,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';
import produtoSemImagemImg from '../../assets/produto-sem-imagem.png';

import { useAuth } from '../../context/AuthContext';
import { useConfigs } from '../../context/ConfigsContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useToast } from '../../context/ToastContext';

interface Venda {
  id: string;
  numero: string;
  baixado_erp: string;
  data_inclusao: Date;
  obs: string;
  cliente: VendaCliente;
  itens: VendaItens[];
}

interface VendaCliente {
  fantasia: string;
  nome: string;
  bairro: string;
  cidade: string;
  endereco: string;
  numero: string;
  estado: string;
}

interface VendaItens {
  id: string;
  qtde: number;
  preco: number;
  produto: VendaItensProduto;
}

interface VendaItensProduto {
  descricao: string;
  imagem_url: string;
}

const Vendas: React.FC = () => {
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  // const history = useHistory();
  const { cores, lerConfig } = useConfigs();
  const { lerIndexedDB } = useIndexedDB();
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(undefined);
  const [carregarComDelay, setCarregarComDelay] = useState(false);

  const inputDataInicial = useRef<HTMLInputElement>(null);
  const inputDataFinal = useRef<HTMLInputElement>(null);

  const formatterCurrency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const dataFormatada = useCallback((data: string) => {
    if (data) {
      if (data !== '') {
        let dataConvertida = parseISO(data);
        dataConvertida = new Date(dataConvertida.valueOf() + dataConvertida.getTimezoneOffset() * 60 * 1000);
        if (isValid(dataConvertida)) {
          return format(dataConvertida, 'dd/MM/yyyy');
        }
      }
    }

    return '';
  }, []);

  const [vendas, setVendas] = useState<Venda[]>([]);

  // const [pesquisaRealizada, setPesquisaRealizada] = useState<string>('');
  // const [pesquisaComResultados, setPesquisaComResultados] = useState(true);
  const [dataInicial, setDataInicial] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [dataInicialErro, setDataInicialErro] = useState<string>('');
  const [dataFinal, setDataFinal] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  const [dataFinalErro, setDataFinalErro] = useState<string>('');

  useEffect(() => {
    // IMAGENS DINÂMICAS
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configLogo
      } }).then(response => {
        setLogoDinamico(response.data);
      });
    } else {
      configLogo = undefined;
    }

    api.get<Venda[]>('/vendas/por-periodo', {
      params: {
        data_inicial: format(new Date(), "yyyy-MM-dd"),
        data_final: format(new Date(), "yyyy-MM-dd"),
      }
    }).then((response) => {
      setVendas(response.data);
    });

    // Delay pra dar tempo de carregar as cores do sistema quando não utiliza login
    setTimeout(() => {
      setCarregarComDelay(true);
    }, 200);
    
  }, [signOut, lerConfig, lerIndexedDB, user.empresa.url_frontend]);

  const aoAlterarDataInicial = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDataInicial(event.target.value);
    setDataInicialErro('');
  }, [setDataInicial, setDataInicialErro]);

  const aoAlterarDataFinal = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDataFinal(event.target.value);
    setDataFinalErro('');
  }, [setDataFinal, setDataFinalErro]);

  const buscarVendas = useCallback(async () => {
    setDataInicialErro('');
    setDataFinalErro('');

    if (!isValid(parseISO(dataInicial)) || !isValid(parseISO(dataFinal))) {
        addToast({
            title: 'Falha na pesquisa',
            description: 'Informe um intervalo de datas válido',
            type: 'info',
        });
        setVendas([]);
        return;
    }

    setVendas([]);

    const respostaVendas = await api.get<Venda[]>('/vendas/por-periodo', {
      params: {
        data_inicial: dataInicial,
        data_final: dataFinal,
      }
    });
    
    setVendas(respostaVendas.data);
  }, [dataInicial, dataFinal, addToast]);

  const totalItem = useCallback((itemPar: VendaItens) => {
    if (!itemPar) {
      return 0;
    }
    return itemPar.qtde * itemPar.preco;
  }, []);

  const totalVenda = useCallback((vendaPar: Venda) => {
    if (!vendaPar) {
      return 0;
    }
    return vendaPar.itens.reduce((soma, item) => soma + (item.qtde * item.preco), 0)
  }, []);

  return ( carregarComDelay ?
    <Container cores={cores}>
      <Header cores={cores} >
        <HeaderContent cores={cores}>
          <Link to="/home">
            <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo"/>
          </Link>
        </HeaderContent>
      </Header>

      <Content>
        <LinhaBuscar>
          <InputData
            variant="outlined"
            margin="normal"
            // required
            // fullWidth
            autoFocus
            id="dataIni"
            label="Data Inicial"
            name="dataIni"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={inputDataInicial}
            value={dataInicial}
            onChange={aoAlterarDataInicial}
            error={dataInicialErro !== ''}
            helperText={dataInicialErro}
          />
          <InputData
            variant="outlined"
            margin="normal"
            // required
            // fullWidth
            autoFocus
            id="dataFim"
            label="Data Final"
            name="dataFim"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={inputDataFinal}
            value={dataFinal}
            onChange={aoAlterarDataFinal}
            error={dataFinalErro !== ''}
            helperText={dataFinalErro}
          />
          <BotaoPesquisarClientes cores={cores}>
            <button
              type="button"
              onClick={buscarVendas}
              // title="Incluir Cliente"
            >
              <div>
                <FiSearch />
              </div>
            </button>
          </BotaoPesquisarClientes>
        </LinhaBuscar>

        <ListaCartoes>
            {vendas.map(venda => (
              <Cartao key={venda.id} cores={cores} >
                  <CabecalhoCartao cores={cores}>
                    <h2>{venda.cliente.fantasia}</h2>
                  </CabecalhoCartao>

                  <p>{venda.cliente.endereco !== '' && venda.cliente.endereco} {venda.cliente.numero !== '' && ', ' + venda.cliente.numero} {venda.cliente.cidade !== '' && '- ' + venda.cliente.cidade + '-' + venda.cliente.estado}</p>

                  <ItensBox>
                    {venda.itens.map(item => (
                      <ItemBox key={item.id} cores={cores}>
                        <ProdutoImagem src={item.produto.imagem_url ? item.produto.imagem_url : produtoSemImagemImg} alt={item.produto.descricao} />
                          <section>
                            <p>{item.produto.descricao}</p>
                            <p>Qtde: {Math.round(item.qtde)}</p>
                            <p>Preço: {formatterCurrency.format(item.preco)}</p>
                            <p>Total item: {formatterCurrency.format(totalItem(item))}</p>
                            {/* <p>{Math.round(item.qtde)} * {formatterCurrency.format(item.preco)} = {formatterCurrency.format(totalItem(item))}</p> */}
                          </section>
                      </ItemBox>
                    ))}
                  </ItensBox>

                  <RodapeCartao cores={cores}>
                    <h3>{dataFormatada(venda.data_inclusao.toString())}</h3>
                    <h3>{formatterCurrency.format(totalVenda(venda))}</h3>
                  </RodapeCartao>
              </Cartao>
            ))}
        </ListaCartoes>
      </Content>

    </Container> : <CirculoCarregandoDiv><CirculoCarregando cores={cores} /></CirculoCarregandoDiv>
  );
};

export default Vendas;