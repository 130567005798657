import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  FiSave,
  FiTrash,
  FiSearch,
} from 'react-icons/fi';

import api from '../../services/api';
import apiViaCep from '../../services/apiViaCep';

import {
  Container,
  CirculoCarregandoDiv,
  CirculoCarregando,
  Header,
  HeaderContent,
  Content,
  BoxCadastro,
  GroupBox,
  GroupBoxLegenda,
  InputCnpjCpf,
  BotaoPesquisarCnpj,
  InputNome,
  InputCep,
  InputEndereco,
  InputBairro,
  InputComplemento,
  InputNumero,
  InputCidade,
  InputUf,
  InputNomeContato,
  InputTelefone,
  BotaoSalvar,
  BotaoCancelar,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';

import { useAuth } from '../../context/AuthContext';
import { useConfigs } from '../../context/ConfigsContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useToast } from '../../context/ToastContext';

interface ClienteCarrinho {
  id: string;
  nome: string;
  fone: string;
  email: string;
}

interface Cliente {
  id: string;
  fantasia: string;
}

interface PessoaContatoGravar {
  tipo: string;
  valor: string;
  obs: string;
}

interface PessoaGravar {
  cnpjcpf: string;
  nome: string;
  fantasia: string;
  // id_origem: string;
  tipo: string;
  cep: string;
  cidade: string;
  estado: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  ibge: string;
  tipo_pessoa: string;
  data: Date;
  usuario: string;
  consumidor: number;
  inscricao_estadual: string;
  tipo_contribuinte: number;
  pais_id: number;
  // tipo_preco: string;
  // forma_pag_id: number;
  seguimento: string;
  vr_lim_credito: number;
  sem_juros: boolean;
  filial: boolean;
  vendedor_id: number;
  contatos: PessoaContatoGravar[];
}

interface RetornoViaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
}

interface RetornoCnpj {
  nome: string;
  logradouro: string;
  numero: string;
  municipio: string;
  bairro: string;
  uf: string;
  cep: string;
  telefone: string;
  fantasia: string;
  complemento: string;
}

const Cliente: React.FC = () => {
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const history = useHistory();
  const { cores, lerConfig } = useConfigs();
  const { gravarIndexedDB } = useIndexedDB();
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(undefined);
  const [carregarComDelay, setCarregarComDelay] = useState(false);

  // States para o form simples
  const [cnpjcpf, setCnpjcpf] = useState<string>('');
  const [cnpjcpfErro, setCnpjcpfErro] = useState<string>('');
  const [tipo, setTipo] = useState<string>('JURIDICA');
  const [nome, setNome] = useState<string>('');
  const [nomeErro, setNomeErro] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [cepErro, setCepErro] = useState<string>('');
  const [endereco, setEndereco] = useState<string>('');
  const [enderecoErro, setEnderecoErro] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [numeroErro, setNumeroErro] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [bairroErro, setBairroErro] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [complementoErro, setComplementoErro] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [cidadeErro, setCidadeErro] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [ufErro, setUfErro] = useState<string>('');
  const [nomeContato, setNomeContato] = useState<string>('');
  const [nomeContatoErro, setNomeContatoErro] = useState<string>('');
  const [telefone, setTelefone] = useState<string>('');
  const [telefoneErro, setTelefoneErro] = useState<string>('');

  // Refs para o cadastro
  const inputCnpjcpf = useRef<HTMLInputElement>(null);
  const inputNome = useRef<HTMLInputElement>(null);
  const inputCep = useRef<HTMLInputElement>(null);
  const inputEndereco = useRef<HTMLInputElement>(null);
  const inputNumero = useRef<HTMLInputElement>(null);
  const inputBairro = useRef<HTMLInputElement>(null);
  const inputComplemento = useRef<HTMLInputElement>(null);
  const inputCidade = useRef<HTMLInputElement>(null);
  const inputUf = useRef<HTMLInputElement>(null);
  const inputNomeContato = useRef<HTMLInputElement>(null);
  const inputTelefone = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // IMAGENS DINÂMICAS
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configLogo
      } }).then(response => {
        setLogoDinamico(response.data);
      });
    } else {
      configLogo = undefined;
    }

    // Delay pra dar tempo de carregar as cores do sistema quando não utiliza login
    setTimeout(() => {
      setCarregarComDelay(true);
    }, 200);

    // setTimeout(() => {
    //   if (inputNome.current) {
    //     inputNome.current.focus();
    //   }
    // }, 3000);

  }, [signOut, lerConfig, user.empresa.url_frontend]);

  const aoAlterarCnpjcpf = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCnpjcpf(event.target.value);
    setCnpjcpfErro('');
  }, [setCnpjcpf, setCnpjcpfErro]);

  const buscarCnpjWebService = useCallback((cnpjPar: string, forcar: boolean) => {
    let cnpjCpfTemp: string = cnpjPar;
    cnpjCpfTemp = cnpjCpfTemp.replace(/[^0-9]/g, ''); // somente números

    if (cnpjCpfTemp.length === 11) {
      const cpfFormatado: string = `${cnpjCpfTemp.substr(0, 3)}.${cnpjCpfTemp.substr(3, 3)}.${cnpjCpfTemp.substr(6, 3)}-${cnpjCpfTemp.substr(9, 2)}`;
      setCnpjcpf(cpfFormatado);
      setTipo('FISICA');
    } else {
      if (cnpjCpfTemp.length === 14) {
        const cnpjFormatado: string = `${cnpjCpfTemp.substr(0, 2)}.${cnpjCpfTemp.substr(2, 3)}.${cnpjCpfTemp.substr(5, 3)}/${cnpjCpfTemp.substr(8, 4)}-${cnpjCpfTemp.substr(12, 2)}`;
        setCnpjcpf(cnpjFormatado);
        setTipo('JURIDICA');
      }
    }

    if (cnpjCpfTemp) {
      if (cnpjCpfTemp !== '' && cnpjCpfTemp.length === 14) {
        if (!nome || forcar) {
          api.get<RetornoCnpj>('/pessoas/consulta-cnpj', {
            params: {
              cnpj: cnpjCpfTemp
            }
          }).then(resposta => {
            const dadosCnpj = resposta.data;
  
            setNome(dadosCnpj.nome);
            setCep(dadosCnpj.cep);
            setEndereco(dadosCnpj.logradouro);
            setNumero(dadosCnpj.numero);
            setBairro(dadosCnpj.bairro);
            setCidade(dadosCnpj.municipio);
            setComplemento(dadosCnpj.complemento);
            setUf(dadosCnpj.uf);
          });
        }
      }
    }
  }, [nome]);

  const aoSairCnpjcpf = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    buscarCnpjWebService(event.target.value, false);
  }, [buscarCnpjWebService]);

  const aoBuscarCnpj = useCallback(() => {
    buscarCnpjWebService(cnpjcpf, true);
  }, [buscarCnpjWebService, cnpjcpf]);

  const aoAlterarNome = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
    setNomeErro('');
  }, [setNome, setNomeErro]);

  const aoAlterarCep = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCep(event.target.value);
    setCepErro('');
  }, [setCep, setCepErro]);

  const aoSairCep = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    let cepTemp: string = event.target.value;
    // Somente números
    cepTemp = cepTemp.replace(/[^0-9]/g, '');

    // const retorno: RetornoViaCep = await apiViaCep.get<RetornoViaCep>(`/${cepTemp}/json/`);
    if (endereco === '') {
      apiViaCep.get<RetornoViaCep>(`/${cepTemp}/json/`).then(response => {
        if (response.data) {
          const retornoCep: RetornoViaCep = response.data;
          if (retornoCep) {
            if (retornoCep.logradouro) {
              setEndereco(retornoCep.logradouro);
            }
            if (retornoCep.localidade) {
              setCidade(retornoCep.localidade);
            }
            if (retornoCep.bairro) {
              setBairro(retornoCep.bairro);
            }
            if (retornoCep.complemento) {
              setComplemento(retornoCep.complemento);
            }
            if (retornoCep.uf) {
              setUf(retornoCep.uf);
            }
            inputNumero.current?.focus();
          }
        }
      });

    }

    if (cepTemp.length === 8) {
      const cepFormat = `${cepTemp.substr(0, 5)}-${cepTemp.substr(5, 3)}`;
      setCep(cepFormat);
    }
  }, [setCep, endereco]);

  const aoAlterarEndereco = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEndereco(event.target.value);
    setEnderecoErro('');
  }, [setEndereco, setEnderecoErro]);

  const aoAlterarNumero = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNumero(event.target.value);
    setNumeroErro('');
  }, [setNumero, setNumeroErro]);

  const aoAlterarBairro = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setBairro(event.target.value);
    setBairroErro('');
  }, [setBairro, setBairroErro]);

  const aoAlterarComplemento = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setComplemento(event.target.value);
    setComplementoErro('');
  }, [setComplemento, setComplementoErro]);

  const aoAlterarCidade = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCidade(event.target.value);
    setCidadeErro('');
  }, [setCidade, setCidadeErro]);

  const aoAlterarUf = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUf(event.target.value);
    setUfErro('');
  }, [setUf, setUfErro]);

  const aoAlterarNomeContato = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNomeContato(event.target.value);
    setNomeContatoErro('');
  }, [setNomeContato, setNomeContatoErro]);

  const aoAlterarTelefone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTelefone(event.target.value);
    setTelefoneErro('');
  }, [setTelefone, setTelefoneErro]);

  const aoSalvar = useCallback(async () => {
    setCnpjcpfErro('');
    setNomeErro('');
    setCepErro('');
    setEnderecoErro('');
    setNumeroErro('');
    setBairroErro('');
    setComplementoErro('');
    setCidadeErro('');
    setUfErro('');
    setNomeContatoErro('');
    setTelefoneErro('');

    let erroValidacao = false;
    // if (cnpjcpf === '') {
    //   setCnpjcpfErro('Informação obrigatória!');
    //   erroValidacao = true;
    // }
    if (nome === '') {
      setNomeErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (endereco === '') {
      setEnderecoErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (numero === '') {
      setNumeroErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (cidade === '') {
      setCidadeErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (uf === '') {
      setUfErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (nomeContato === '') {
      setNomeContatoErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (telefone === '') {
      setTelefoneErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description: 'Efetue as correções indicadas em cada campo e tente novamente.'
      });
      return;
    }

    const contatoGravar: PessoaContatoGravar = {
      tipo: 'FONE',
      valor: telefone.toUpperCase(),
      obs: nomeContato.toUpperCase(),
    };

    let dataAgora = new Date();
    dataAgora.setHours(0,0,0,0);

    const pessoaGravar: PessoaGravar = {
      cnpjcpf: cnpjcpf,
      nome: nome.toUpperCase(),
      fantasia: nome.toUpperCase(),
      tipo: tipo,
      cep: cep,
      cidade: cidade.toUpperCase(),
      estado: uf.toUpperCase(),
      endereco: endereco.toUpperCase(),
      numero: numero.toUpperCase(),
      complemento: complemento.toUpperCase(),
      bairro: bairro.toUpperCase(),
      ibge: '',
      tipo_pessoa: 'C',
      data: dataAgora,
      usuario: 'CATALOGO-WEB:' + user.name,
      consumidor: 1,
      inscricao_estadual: '',
      tipo_contribuinte: 1,
      pais_id: 100,
      seguimento: '',
      vr_lim_credito: 0,
      sem_juros: false,
      filial: false,
      vendedor_id: user.id_origem,
      contatos: [ contatoGravar ],
    };

    // console.log(pessoaGravar);
    const retornoGravacao = await api.post('pessoas', pessoaGravar);
    if (retornoGravacao.status !== 200) {
      addToast({
        type: 'error' ,
        title: 'Falha ao Incluir Cliente! Tente novamente em alguns minutos!',
      });
    } else {
      addToast({
        type: 'success',
        title: 'Cliente incluído com sucesso!!!',
      });

      // TEM QUE SALVAR O NOVO CLIENTE NO CARRINHO ANTES DE MUDAR DE PÁGINA
      const novoCliente: Cliente = retornoGravacao.data;
      const clienteSimplesTemp: ClienteCarrinho = {
        id: novoCliente.id,
        nome: novoCliente.fantasia,
        email: '',
        fone: '',
      };
      gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-cliente-simples`, clienteSimplesTemp);

      history.push('carrinho');
    }

  }, [
    addToast,
    cnpjcpf,
    tipo,
    nome,
    cep,
    endereco,
    numero,
    bairro,
    complemento,
    cidade,
    uf,
    nomeContato,
    telefone,
    history,
    user.name,
    user.id_origem,
    user.empresa.url_frontend,
    gravarIndexedDB,
  ]);

  const aoCancelar = useCallback(async () => {
    history.push('pesquisa-clientes');
  }, [history]);

  return ( carregarComDelay ?
    <Container cores={cores}>
      <Header cores={cores} >
        <HeaderContent cores={cores}>
          <Link to="/home">
            <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo"/>
          </Link>
        </HeaderContent>
      </Header>

      <Content>

        <BoxCadastro>
          <GroupBox>
            <GroupBoxLegenda>Inclusão de Cliente</GroupBoxLegenda>

            <div>
              <InputCnpjCpf
                variant="outlined"
                margin="normal"
                id="cnpjcpf"
                label="CNPJ / CPF"
                name="cnpjcpf"
                inputRef={inputCnpjcpf}
                value={cnpjcpf}
                onChange={aoAlterarCnpjcpf}
                onBlur={aoSairCnpjcpf}
                error={cnpjcpfErro !== ''}
                helperText={cnpjcpfErro}
              />

              <BotaoPesquisarCnpj cores={cores}>
                <button
                  type="button"
                  onClick={aoBuscarCnpj}
                  // title="Incluir Cliente"
                >
                  <div>
                    <FiSearch />
                  </div>
                </button>
              </BotaoPesquisarCnpj>
            </div>

            <InputNome
              variant="outlined"
              margin="normal"
              id="nomeCliente"
              label="Nome"
              name="nomeCliente"
              inputRef={inputNome}
              value={nome}
              onChange={aoAlterarNome}
              error={nomeErro !== ''}
              helperText={nomeErro}
            />

            <div>
              <InputCep
                variant="outlined"
                margin="normal"
                id="cep"
                label="CEP"
                name="cep"
                inputRef={inputCep}
                value={cep}
                onChange={aoAlterarCep}
                onBlur={aoSairCep}
                error={cepErro !== ''}
                helperText={cepErro}
              />

              <InputEndereco
                variant="outlined"
                margin="normal"
                id="enderecoCliente"
                label="Endereço"
                name="enderecoCliente"
                inputRef={inputEndereco}
                value={endereco}
                onChange={aoAlterarEndereco}
                error={enderecoErro !== ''}
                helperText={enderecoErro}
              />
            </div>
              

            <div>
              <InputNumero
                variant="outlined"
                margin="normal"
                id="numeroCliente"
                label="Número"
                name="numeroCliente"
                inputRef={inputNumero}
                value={numero}
                onChange={aoAlterarNumero}
                error={numeroErro !== ''}
                helperText={numeroErro}
              />

              <InputBairro
                variant="outlined"
                margin="normal"
                id="bairroCliente"
                label="Bairro"
                name="bairroCliente"
                inputRef={inputBairro}
                value={bairro}
                onChange={aoAlterarBairro}
                error={bairroErro !== ''}
                helperText={bairroErro}
              />
            </div>

            <InputComplemento
              variant="outlined"
              margin="normal"
              id="complementoCliente"
              label="Complemento"
              name="complementoCliente"
              inputRef={inputComplemento}
              value={complemento}
              onChange={aoAlterarComplemento}
              error={complementoErro !== ''}
              helperText={complementoErro}
            />

            <div>
              <InputCidade
                variant="outlined"
                margin="normal"
                id="cidadeCliente"
                label="Cidade"
                name="cidadeCliente"
                inputRef={inputCidade}
                value={cidade}
                onChange={aoAlterarCidade}
                error={cidadeErro !== ''}
                helperText={cidadeErro}
              />

              <InputUf
                variant="outlined"
                margin="normal"
                id="ufCliente"
                label="UF"
                name="ufCliente"
                inputRef={inputUf}
                value={uf}
                onChange={aoAlterarUf}
                error={ufErro !== ''}
                helperText={ufErro}
              />
            </div>

            <div>
              <InputNomeContato
                variant="outlined"
                margin="normal"
                id="nomeContato"
                label="Nome Contato"
                name="nomeContato"
                inputRef={inputNomeContato}
                value={nomeContato}
                onChange={aoAlterarNomeContato}
                error={nomeContatoErro !== ''}
                helperText={nomeContatoErro}
              />

              <InputTelefone
                variant="outlined"
                margin="normal"
                id="telefone"
                label="Telefone"
                name="telefone"
                inputRef={inputTelefone}
                value={telefone}
                onChange={aoAlterarTelefone}
                error={telefoneErro !== ''}
                helperText={telefoneErro}
              />
            </div>

            <div>
              <BotaoSalvar cores={cores}>
                <button
                  type="button"
                  onClick={aoSalvar}
                >
                  <div>
                    <FiSave />
                    <span>SALVAR</span>
                  </div>
                </button>
              </BotaoSalvar>

              <BotaoCancelar cores={cores}>
                <button
                  type="button"
                  onClick={aoCancelar}
                >
                  <div>
                    <FiTrash />
                    <span>CANCELAR</span>
                  </div>
                </button>
              </BotaoCancelar>
            </div>

          </GroupBox>

        </BoxCadastro>

      </Content>

    </Container> : <CirculoCarregandoDiv><CirculoCarregando cores={cores} /></CirculoCarregandoDiv>
  );
};

export default Cliente;
