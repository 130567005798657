import React, { createContext, useCallback, useState, useContext } from 'react';

import { useStorage } from './StorageContext';
import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  empresa: Empresa;
  id_origem: number;
}

interface Empresa {
  nome_fantasia: string;
  url_frontend: string;
  utiliza_login: string;
  configsStr: string;
  whats_link: string;
  whats_formatado: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const storage = useStorage();

  const [data, setData] = useState<AuthState>(() => {
    const token = storage.ler('token');
    const user = storage.ler('user');
    if (token && user) {
      //define header padrao para todas as requisicoes - executa quando dá F5/refresh na página
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
        user: JSON.parse(user),
      }
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const urlEmpresa = storage.ler('empresa');
    const response = await api.post('sessions', {
      email,
      password,
      url_frontend: urlEmpresa,
    });

    const { token, user } = response.data;
    storage.gravar('token', token);
    storage.gravar('user', user);

    //define header padrao para todas as requisicoes - executa quando loga
    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, [storage]);

  const signOut = useCallback(() => {
    storage.excluir('token');
    storage.excluir('user');

    setData({} as AuthState);
  }, [storage]);

  const updateUser = useCallback((user: User) => {
    storage.gravar('user', user);

    setData({
      token: data.token,
      user,
    });
  }, [setData, data.token, storage]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
