import React, { createContext, useContext, useCallback } from 'react';
import { createCipheriv, createDecipheriv, randomBytes } from 'crypto';

interface CriptografiaContextData {
  encriptar(valor: string): string;
  decriptar(valor: string): string;
}

const CriptografiaContext = createContext<CriptografiaContextData>({} as CriptografiaContextData);

const CriptografiaProvider: React.FC = ({ children }) => {
  const encriptar = useCallback((valor: string): string => {
    if (!process.env.REACT_APP_CRYPTO_ALGORITMO) {
      throw new Error("Algoritmo de criptografia está vazio.");
    }
    if (!process.env.REACT_APP_CRYPTO_CHAVE) {
      throw new Error("Chave de criptografia está vazia.");
    }

    const iv = randomBytes(16);
    const algoritmo: string = process.env.REACT_APP_CRYPTO_ALGORITMO;
    const chave: string = process.env.REACT_APP_CRYPTO_CHAVE;

    const cipher = createCipheriv(algoritmo, chave, iv);
    const bufferEncriptado = Buffer.concat([cipher.update(valor, 'utf8'), cipher.final()]);

    return iv.toString('hex') + ':' + bufferEncriptado.toString('hex');;
  }, []);

  const decriptar = useCallback((valor: string): string => {
    if (!process.env.REACT_APP_CRYPTO_ALGORITMO) {
      throw new Error("Algoritmo de criptografia está vazio.");
    }
    if (!process.env.REACT_APP_CRYPTO_CHAVE) {
      throw new Error("Chave de criptografia está vazia.");
    }

    const algoritmo: string = process.env.REACT_APP_CRYPTO_ALGORITMO;
    const chave: string = process.env.REACT_APP_CRYPTO_CHAVE;

    const textParts = valor.split(':');
    const iv = Buffer.from(textParts.shift()!, 'hex');
    const encryptedText = Buffer.from(textParts.join(':'), 'hex');
    const decipher = createDecipheriv(algoritmo, chave, iv);
    const decrypted = Buffer.concat([decipher.update(encryptedText), decipher.final()]);
    return decrypted.toString('utf8');
  }, []);

  return (
    <CriptografiaContext.Provider value={{ encriptar, decriptar }}>
      {children}
    </CriptografiaContext.Provider>
  );
}

function useCriptografia(): CriptografiaContextData {
  const context = useContext(CriptografiaContext);

  if (!context) {
    throw new Error('useCriptografia deve ser usado dentro de um CriptografiaProvider');
  }

  return context;
}

export { CriptografiaProvider, useCriptografia };
