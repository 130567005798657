import React from 'react';

import { CriptografiaProvider } from './CriptografiaContext';
import { StorageProvider } from './StorageContext';
import { IndexedDBProvider } from './IndexedDBContext';
import { AuthProvider } from './AuthContext';
import { ToastProvider } from './ToastContext';
import { ConfigsProvider } from './ConfigsContext';

const AppProvider: React.FC = ({ children }) => (
  <CriptografiaProvider>
    <StorageProvider>
      <IndexedDBProvider>
        <AuthProvider>
          <ConfigsProvider>
            <ToastProvider>
              { children }
            </ToastProvider>
          </ConfigsProvider>
        </AuthProvider>
      </IndexedDBProvider>
    </StorageProvider>
  </CriptografiaProvider>
);

export default AppProvider;
