import React from 'react';

import { Container, Content } from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';

const NaoEncontrada: React.FC = () => {

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Logo Sizex" />

        <h1>Página não encontrada!</h1>
        <p>Desculpe, a página que você está procurando não existe.</p>
        {/* <span>Em caso de dúvida contate a Sizex que estaremos prontos para ajudar.</span> */}
      </Content>
    </Container>
  );
};

export default NaoEncontrada;
