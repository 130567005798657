import styled, { keyframes } from 'styled-components';
// import { shade } from 'polished';
import { CircularProgress, Button } from '@material-ui/core';

import ICoresData from '../../context/ICoresData';

interface CoresProps {
  cores: ICoresData;
}

export const Container = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.cores.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.cores.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.cores.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.cores.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.cores.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.cores.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.cores.textoClaro};
    }
  }
`;

export const CirculoCarregandoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const CirculoCarregando = styled(CircularProgress)<CoresProps>`
  color: #E6E600 !important;
  color: ${props => props.cores.secundaria} !important;

  width: 80px !important;
  height: 80px !important;
  @media (max-width: 610px) {
    width: 40px !important;
    height: 40px !important;
  }
`;

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const Header = styled.header<CoresProps>`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 16px 0;
  background: ${props => props.cores.primaria};
  /* width: auto; */
  flex: 1;

  animation: ${surgirDeCima} 1s;

  height: 110px;
  @media (max-width: 610px) {
    height: 80px;
  }
`;

export const HeaderContent = styled.div<CoresProps>`
  max-width: 1120px;
  /* dá uma alinhada no centro no header content */
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin: 0 24px;
  }

  /* estiliza a primeira imagem: a logo */
  a {
    img {
      height: 80px;
      @media (max-width: 610px) {
        height: 56px;
      }
      cursor: pointer;
    }
  }

`;

export const Content = styled.main`
  max-width: 1120px;
  min-height: 600px;
  width: 100%;
  margin: 0 auto;

  /* @media (max-width: 610px) {
    min-height: 600px;
  } */

  margin-top: 110px;
  @media (max-width: 610px) {
    margin-top: 80px;
  }
  /* margin-left: auto; */
  /* margin-right: auto; */

  padding-left: 8px;
  padding-right: 8px;
`;

export const LinhaBuscar = styled.div`
  display: flex;
`;

export const Buscar = styled.div<CoresProps>`
  flex: 1;
  display: flex;
  background-color: #FFF;
  margin-top: 12px;
  height: 52px;
  border: 1px solid ${props => props.cores.secundaria};
  border-radius: 20px;

  /* @media (max-width: 610px) {
    display: flex;
  } */

  animation: ${surgirDeCima} 1s;

  input {
    flex: 1;
    margin: 0 12px;
    padding: 0 16px;
    border: none;
    color: ${props => props.cores.texto};
    font-size: 16px;

    ::placeholder {
      color: ${props => props.cores.textoClaro};
    }
  }

  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    margin-right: 12px;
  }

  svg {
    margin-top: 4px;
    margin-right: 0px;
    color: ${props => props.cores.texto};

    transition: color 0.2s;

    &:hover {
        color: ${props => props.cores.textoShade};
    }
  }
`;

export const BotaoPesquisarClientes = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 80px; //tava 100%
  display: flex;
  margin-bottom: 0px;
  margin-top: 12px;
  padding: 0 8px;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 50px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 32px;
        width: 32px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;

export const ListaCartoes = styled.div`
    /* flex: 1; */
    width: 100%;
`;

export const Cartao = styled.div`
  animation: ${appearFromLeft} 1s;

  margin-top: 20px;
  width: 100%;

  -webkit-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  -moz-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);

  border-radius: 15px;
  border: 2px solid whitesmoke;

  padding: 15px;

  display: flex;
  flex-direction: column;

  transition: all ease 0.3s;

  &:hover {
    background-color: #f2f2f2;
  }
  z-index: 1;

  p {
    color: #999999;
    margin-top: 4px;

    /* a partir do segundo p */
    /* & + p {
      margin-top: 4px;
    } */
  }
`;

export const CabecalhoCartao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h2 {
    color: #595959;
    flex: 1;
    margin-right: 10px;
    margin-top: 4px;
  }

  svg {
  }
  z-index: 1;
`;

export const AreaBotoes = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Botao = styled(Button)`
  margin-top: 4px !important;
  background-color: var(--cor-login-botao) !important;
  color: var(--cor-login-fundo) !important;

  width: 145px !important;

  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
  }

  /* & + button {
    margin-left: 10px;
  } */
`;