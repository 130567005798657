import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect
} from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useStorage } from '../context/StorageContext';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...restProps }) => {
  const { user, signOut } = useAuth();
  const storage = useStorage();

  return (
    <ReactDOMRoute
      {...restProps}
      // uso do location é para manter o histórico e o voltar funcionar
      render={({ location }) => {

        // Obtém empresa da rota, se rota raíz
        let empresa: string | null = '';
        if (restProps.path === '/:empresa') {
          if (restProps.location) {
            empresa = restProps.location.pathname.replace('/', '');
            const empresaAnt = storage.ler('empresa');
            if (empresaAnt !== empresa) {
              signOut();
            }
            storage.gravar('empresa', empresa);
          }
        } else {
          empresa = storage.ler('empresa')
        }
        // if (!empresa) {
          // redirecionar para alguma página de not found ou avisar que url está incompleta
        // }


        // isprivate === issigned
        // true/true = OK
        // true/false = redirecionar para o login
        // false/true = redirecionar para a home
        // false/false = OK
        // se é rota privada e o usuário existe, manda o componente informado na FiRotateCcw, senão redireciona para o login ou para a home se não for privado
        // return isPrivate === !!user ? (<Component />) : (<Redirect to={{ pathname: isPrivate ? `/${empresa}` : '/home',
        // state: { from: location }, }} />);
        // Reescrito de forma mais clara
        if (isPrivate && user) {
          return (<Component />);
        } else if (!isPrivate && !user) {
          return (<Component />);
        } else if (isPrivate && !user) {
          return (<Redirect to={{ pathname: `/${empresa}`, state: { from: location }, }} />)
        } else if (!isPrivate && user) {
          return (<Redirect to={{ pathname: '/home', state: { from: location }, }} />)
        }

      }}
    />
  );
};

export default Route;
