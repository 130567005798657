import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { shade } from 'polished';

import { useStorage } from './StorageContext';

import api from '../services/api';

import IConfigsContextData from './IConfigsContextData';
import ICoresData from './ICoresData';
import IEmpresaConfigs from './IEmpresaConfigs';

const ConfigsContext = createContext<IConfigsContextData>({} as IConfigsContextData);

const ConfigsProvider: React.FC = ({ children }) => {
  const storage = useStorage();
  const [cores, setCores] = useState<ICoresData>({} as ICoresData);
  // const [configs, setConfigs] = useState<IEmpresaConfigs[]>([]); - deixei no storage por enquanto, mas daria pra deixar aqui também

  const definirCores = useCallback((configs: IEmpresaConfigs[]) => {
    let primaria = '#0F0E0E';
    let secundaria = '#c4cc45';
    let texto = '#537b7d';
    let textoClaro = '#bfbfbf';

    if (configs) {
      if (configs.length > 0) {
        let configTemp = configs.filter(itemFiltro => itemFiltro.parametro === 'TEMA - COR PRIMÁRIA')[0];
        if (configTemp) {
          if (configTemp.valor) {
            if (configTemp.valor !== '') {
              primaria = configTemp.valor;
            }
          }
        }

        configTemp = configs.filter(itemFiltro => itemFiltro.parametro === 'TEMA - COR SECUNDÁRIA')[0];
        if (configTemp) {
          if (configTemp.valor) {
            if (configTemp.valor !== '') {
              secundaria = configTemp.valor;
            }
          }
        }

        configTemp = configs.filter(itemFiltro => itemFiltro.parametro === 'TEMA - COR TEXTO')[0];
        if (configTemp) {
          if (configTemp.valor) {
            if (configTemp.valor !== '') {
              texto = configTemp.valor;
            }
          }
        }

        configTemp = configs.filter(itemFiltro => itemFiltro.parametro === 'TEMA - COR TEXTO CLARO')[0];
        if (configTemp) {
          if (configTemp.valor) {
            if (configTemp.valor !== '') {
              textoClaro = configTemp.valor;
            }
          }
        }
      }
    }

    setCores({
      primaria: primaria,
      primariaShade: shade(0.2, primaria),
      secundaria: secundaria,
      secundariaShade: shade(0.2, secundaria),
      texto: texto,
      textoShade: shade(0.2, texto),
      textoClaro: textoClaro,
      textoClaroShade: shade(0.2, textoClaro),
    });
  }, []);

  const lerConfig = useCallback((parametro: string): string => {
    const configsStorageStr = storage.ler('configs');
    if (!configsStorageStr) {
      return '';
    }
    const configsStorageParsed: IEmpresaConfigs[] = JSON.parse(configsStorageStr);
    if (!configsStorageParsed) {
      return '';
    }
    if (configsStorageParsed.length === 0) {
      return '';
    }
    const configLer = configsStorageParsed.filter(item => item.parametro === parametro.toUpperCase())[0];
    if (!configLer) {
      return '';
    }
    if (!configLer.valor) {
      return '';
    }
    return configLer.valor;
  }, [storage]);

  useEffect(() => {
    const empresaUrl = storage.ler('empresa');
    if (empresaUrl) {
      api.get<IEmpresaConfigs[]>('/empresas/configs/' + empresaUrl).then(response => {
        storage.gravar('configs', response.data)
        // setConfigs(response.data); por enquanto no storage
        definirCores(response.data);
      });
    }
  }, [storage, definirCores]);

  return (
    <ConfigsContext.Provider value={ { cores, definirCores, lerConfig } }>
      {children}
    </ConfigsContext.Provider>
  );
}

function useConfigs(): IConfigsContextData {
  const context = useContext(ConfigsContext);

  if (!context) {
    throw new Error('useConfigs deve ser usado dentro de um ConfigsProvider');
  }

  return context;
}

export { ConfigsProvider, useConfigs };
