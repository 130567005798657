import styled, { keyframes } from 'styled-components';
// import { shade } from 'polished';
import { CircularProgress, TextField } from '@material-ui/core';

import ICoresData from '../../context/ICoresData';

interface CoresProps {
  cores: ICoresData;
}

export const Container = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.cores.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.cores.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.cores.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.cores.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.cores.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.cores.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.cores.textoClaro};
    }
  }
`;

export const CirculoCarregandoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const CirculoCarregando = styled(CircularProgress)<CoresProps>`
  color: #E6E600 !important;
  color: ${props => props.cores.secundaria} !important;

  width: 80px !important;
  height: 80px !important;
  @media (max-width: 610px) {
    width: 40px !important;
    height: 40px !important;
  }
`;

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const Header = styled.header<CoresProps>`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 16px 0;
  background: ${props => props.cores.primaria};
  /* width: auto; */
  flex: 1;

  animation: ${surgirDeCima} 1s;

  height: 110px;
  @media (max-width: 610px) {
    height: 80px;
  }
`;

export const HeaderContent = styled.div<CoresProps>`
  max-width: 1120px;
  /* dá uma alinhada no centro no header content */
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin: 0 24px;
  }

  /* estiliza a primeira imagem: a logo */
  a {
    img {
      height: 80px;
      @media (max-width: 610px) {
        height: 56px;
      }
      cursor: pointer;
    }
  }

`;

export const Content = styled.main`
  max-width: 1120px;
  min-height: 600px;
  width: 100%;
  margin: 0 auto;

  /* @media (max-width: 610px) {
    min-height: 600px;
  } */

  margin-top: 110px;
  @media (max-width: 610px) {
    margin-top: 80px;
  }
  /* margin-left: auto; */
  /* margin-right: auto; */

  padding-left: 8px;
  padding-right: 8px;
`;

export const GroupBox = styled.fieldset`
  border: 1px solid #c4cc45 !important;
  border-radius: 10px;
  padding: 0 20px 15px !important;
  margin-top: 25px;
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: #c4cc45;
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;

export const BoxCadastro = styled.div`
  animation: ${fadeIn} 1s;
  margin-top: 40px;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  /* background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  /* transition: box-shadow 0.2s; */

  /* &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  } */

  padding: 0 8px;

  div {
    display: flex;
  }
`;

export const InputCnpjCpf = styled(TextField)`
  width: 100%;
`;

export const BotaoPesquisarCnpj = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 70px; //tava 100%
  display: flex;
  margin-bottom: 0px;
  margin-top: 15px;
  margin-left: 1px;
  padding: 0 0;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 58px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 32px;
        width: 32px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;

export const InputNome = styled(TextField)`
  width: 100%;
`;

export const InputCep = styled(TextField)`
  width: 140px;
  margin-right: 12px !important;
`;

export const InputEndereco = styled(TextField)`
  width: 100%;
`;

export const InputNumero = styled(TextField)`
  margin-right: 12px !important;
  width: 140px;
`;

export const InputBairro = styled(TextField)`
  width: 100%;
`;

export const InputComplemento = styled(TextField)`
  width: 100%;
`;

export const InputCidade = styled(TextField)`
  width: 100%;
`;

export const InputUf = styled(TextField)`
  margin-left: 12px !important;
  width: 100px;
`;

export const InputNomeContato = styled(TextField)`
  margin-right: 6px !important;
  width: 100%;
`;

export const InputTelefone = styled(TextField)`
  margin-left: 6px !important;
  width: 100%;
`;

export const BotaoSalvar = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0 8px;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 30px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;

export const BotaoCancelar = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0 8px;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 30px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;