import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

import { useConfigs } from '../../context/ConfigsContext';

// como não vou sobrescrever nenhuma prop, posso declarar um type ao invés de interface
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

// children é a prop onde vem o conteúdo da tag - o texto do botão - funciona pra todos
const Button: React.FC<ButtonProps> = ({ children, loading, ...restProps }) => {
  const { cores } = useConfigs();

  return (
    <Container type="button" {...restProps} cores={cores}>
      {loading ? 'Carregando...' : children}
    </Container>
  )
};

export default Button;
