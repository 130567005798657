import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
import { CircularProgress } from '@material-ui/core';

import bannerImg from '../../assets/banner-site.png';

import ICoresData from '../../context/ICoresData';

interface CarrinhoProps {
  utiliza_login: string;
}

interface CarrinhoConteudoProps {
  utiliza_login: string;
  cores: ICoresData;
  animacao: boolean;
}

interface TagItemProps {
  marcado: boolean;
  cores: ICoresData;
}

interface DescricaoBuscaProps {
  textoBusca: string;
  cores: ICoresData;
}

interface CoresProps {
  cores: ICoresData;
}

interface BannerProps {
  banner1: string | undefined;
}

interface ProdutoBoxProps {
  animacao: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CirculoCarregandoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const CirculoCarregando = styled(CircularProgress)<CoresProps>`
  color: #E6E600 !important;
  color: ${props => props.cores.secundaria} !important;

  width: 80px !important;
  height: 80px !important;
  @media (max-width: 610px) {
    width: 40px !important;
    height: 40px !important;
  }
`;

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const surgirDeCimaMenu = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const moverParaCarrinho = keyframes`
  0% {
    opacity: 1;
    transform: translate(0);
  }
  85% {
    opacity: 0;
    transform: translate(400px,-400px);
  }
  100% {
    opacity: 1;
    transform: translate(0,0);
  }
`;

const aumentarCarrinho = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Header = styled.header<CoresProps>`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 16px 0;
  background: ${props => props.cores.primaria};
  /* width: auto; */
  flex: 1;

  animation: ${surgirDeCima} 1s;

  height: 110px;
  @media (max-width: 610px) {
    height: 80px;
  }
`;

export const HeaderContent = styled.div<CoresProps>`
  max-width: 1120px;
  /* dá uma alinhada no centro no header content */
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin: 0 24px;
  }

  /* estiliza a primeira imagem: a logo */
  > img {
    height: 80px;
    @media (max-width: 610px) {
      height: 56px;
    }
    cursor: pointer;
  }

  strong {
    /* ocupa todo o espaco possivel na esquerda com margem */
    margin-left: auto;

    text-decoration: none;
    color: ${props => props.cores.secundaria};
    font-size: 16px;
  }
`;

export const Buscar = styled.div<CoresProps>`
  flex: 1;
  display: flex;
  margin-left: 28px;
  background-color: #FFF;
  height: 48px;
  border: 1px solid #FFF;
  border-radius: 20px;
  min-width: 50px;

  @media (max-width: 610px) {
    display: none;
  }

  input {
    flex: 1;
    margin: 0 16px;
    padding: 0 12px;
    border: none;
    color: ${props => props.cores.texto};

    ::placeholder {
      color: ${props => props.cores.textoClaro};
    }
  }

  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  svg {
    margin-top: 4px;
    margin-right: 12px;
    color: ${props => props.cores.texto};

    transition: color 0.2s;

    &:hover {
        color: ${props => props.cores.textoShade}
    }
  }
`;

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const Profile = styled.div<CoresProps>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;

  @media (max-width: 610px) {
    margin-left: auto;
  }

  strong {
    font-weight: 700;
    color: ${props => props.cores.secundaria};
  }

  img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;

    @media (max-width: 610px) {
      width: 44px;
      height: 44px;
    }
  }

  ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFF;
    border-radius: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    animation: ${fadeIn} 0.5s;
    width: 160px;
    z-index: 1;

    li {
      border-bottom: 1px solid #eee;

      display: block;
      button {
        padding: 10px 15px;
        background: transparent;
        border: 0;
        width: 100%;
        display: flex;
        align-items: center;
        svg {
          color: ${props => props.cores.secundaria};
          height: 24px;
          width: 24px;
        }
        span {
          color: ${props => props.cores.texto};
          font-size: 20px;
          margin-left: 8px;
          transition: color 0.2s;
        }

        &:hover {
          span {
            color: ${props => props.cores.secundaria}
          }
        }
      }
    }

    // último item do menu sem borda
    li:last-child {
      border-bottom: none;
    }

  }

  // mostrar menu no hover
  &:hover {
    ul {
      display: block;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    @media (max-width: 610px) {
      font-size: 14px;
      margin-left: 8px;
      line-height: 20px;
    }

    span {
      color: #f4ede8;

      @media (max-width: 610px) {
        font-size: 14px;
      }
    }

    strong {
      @media (max-width: 610px) {
        font-size: 14px;
      }
    }

  }
`;

export const CarrinhoCompras = styled.div<CarrinhoProps>`
  ${ props => props.utiliza_login === 'N' &&
    css`@media (max-width: 610px) {
      margin-left: auto;
    }`
  }
`;

export const CarrinhoComprasConteudo = styled.div<CarrinhoConteudoProps>`
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    color: #f4ede8;
    height: 32px;
    width: 32px;

    @media (max-width: 610px) {
      height: 28px;
      width: 28px;
    }

    transition: color 0.2s;

    &:hover {
        color: ${props => props.cores.textoShade}
    }

    ${ props => props.animacao &&
      css`
        animation: ${aumentarCarrinho} 1s;
      `
    }
  }

  span {
    background-color: ${props => props.cores.secundaria};
    border-radius: 50%;
    padding: 3px 6px;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    position: absolute;
    margin-left: 16px;
    margin-top: -28px;

    ${ props => props.animacao &&
      css`
        animation: ${aumentarCarrinho} 1s;
      `
    }
  }

`;

export const BuscarMobile = styled.div<CoresProps>`
  flex: 1;
  display: none;
  background-color: #FFF;
  margin-top: 80px;
  min-height: 40px;
  border-bottom: 1px solid black;

  @media (max-width: 610px) {
    display: flex;
  }

  animation: ${surgirDeCima} 1s;

  input {
    flex: 1;
    /* margin: 0 16px; */
    padding: 0 16px;
    border: none;
    color: ${props => props.cores.texto};
    font-size: 16px;

    ::placeholder {
      color: ${props => props.cores.textoClaro};
    }
  }

  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    margin-right: 12px;
  }

  svg {
    margin-top: 4px;
    margin-right: 0px;
    color: ${props => props.cores.texto};

    transition: color 0.2s;

    &:hover {
        color: ${props => props.cores.textoShade};
    }
  }
`;

export const BannerContainer = styled.div<BannerProps>`
  /* vai fazer ocupar todo o espaço menos os 700px q defini no content */
  flex: 1;
  background: url(${props => props.banner1 ? props.banner1 : bannerImg}) no-repeat center;
  /* pra cobrir todo o tamanho sobrando */
  background-size: cover;
  min-height: 300px;

  /* fixar header */
  margin-top: 110px;
  @media (max-width: 610px) {
    margin-top: 0;
  }

  animation: ${surgirDeCima} 1s;

  @media (max-width: 610px) {
    min-height: 200px;
  }
`;

export const GruposContainer = styled.div<CoresProps>`
  min-height: 50px;
  background-color: ${props => props.cores.secundaria};
  animation: ${surgirDeCima} 1s;
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 18px;

  ul{
    flex: 1;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1150px) {
      margin-left: 24px;
      margin-right: 24px;
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;

      button {
        background: transparent;
        border: none;
        color: #FFF;
        font-size: 18px;
        display: flex;
        align-items: center;

        &:hover {
          color: ${shade(0.8, '#FFF')}
        }
      }

      svg {
        width: 28px;
        height: 28px;
        margin-right: 3px;
      }

      transition: color 0.2s;
      &:hover {
        color: ${shade(0.8, '#FFF')}
      }
    }

    // abrir menu de contexto de grupos
    li:first-child {
      position: relative;

      // menu de contexto de grupo
      section {
        display: none;
        ul {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: ${props => props.cores.secundaria};
          border-radius: 0 0 16px 16px;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          list-style-type: none;
          padding: 0;
          margin: 0;
          animation: ${surgirDeCimaMenu} 0.5s;
          z-index: 1;
          height: 400px;

          display: grid;
          grid-auto-flow: column;
          grid-template-rows: repeat(auto-fill, minmax(45px, 1fr));
          grid-auto-columns: min-content;
          gap: 5px;
          padding-top: 10px;

          div {
            button {
              padding: 8px 16px;
              background: transparent;
              border: 0;
              width: auto;
              display: inline-block;

              span {
                color: #FFF;
                font-size: 16px;
                margin-left: 4px;
                /* width: 150px; */
                white-space: nowrap;

                transition: color 0.2s;
                &:hover {
                  color: ${shade(0.8, '#FFF')}
                }
              }


            }
          }

        }
      }

      // mostrar menu no hover
      &:hover {
        section {
          display: block;
        }
      }

    }

    @media (max-width: 610px) {
      li:not(:first-child) {
        display: none;
      }
    }
  }

  @media (max-width: 610px) {
    & {
      min-height: 40px;

      ul {
        li {
          button {
            font-size: 16px;
          }
        }
      }
    }
  }
`;

export const TagContainerSticky = styled.div`
  /* DIV PARA FIXAR MENU NO TOPO AO ROLAR TELA */
  position: sticky;
  top: 110px;
  z-index: 1;
  background-color: #FFF;
  padding-bottom: 8px;

  @media (max-width: 610px) {
    top: 80px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  animation: ${surgirDeCima} 1s;
  margin-left: 2px;
  margin-right: 2px;
`;

export const TagList = styled.ul`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  list-style-type: none;

  /* Esconde a barra de rolagem */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

export const TagItem = styled.li<TagItemProps>`
  display: inline-block;
  background-color: #eee;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 16px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.cores.texto};
  transition: color 0.2s;
  transition: background-color 0.2s;

  /* li + li { */
    margin: 2px;
  /* } */

  ${ props => props.marcado ?
    css`
      &:hover {
        color: ${props.cores.texto};
    }`
    :
    css`
      &:hover {
      color: ${props.cores.textoShade};
    }`
  }

  ${ props => props.marcado &&
    css`
      background-color: ${props.cores.secundaria};
      color: #FFF;
    `
  }
`;

export const Content = styled.main`
  min-height: 600px;

  @media (max-width: 610px) {
    min-height: 600px;
  }
`;

export const DescricaoBusca = styled.p<DescricaoBuscaProps>`
  margin: 32px 64px;
  font-size: 20px;
  font-weight: 700;

  display: ${props => props.textoBusca === '' ? 'none' : 'block'};
  ${props => props.textoBusca !== '' && css`color: ${props.cores.texto}`};
`;

export const BuscaSemResultados = styled.div<CoresProps>`
  margin: 32px 64px;
  background-color: #eee;
  min-height: 100px;
  padding: 24px;

  h3 {
    font-weight: 700;
    color: ${props => props.cores.texto}
  }

  p {
    margin-top: 12px;
    color: ${props => props.cores.texto}
  }
`;

export const GaleriaProdutos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem;
  margin-top: 8px;

  /* Para evitar que a animação gere uma barra de rolagem */
  overflow: hidden;
`;

export const ProdutoBox = styled.div`
  animation: ${fadeIn} 1s;
  min-height: 250px;
`;

export const ProdutoAnimatedBox = styled.div<ProdutoBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.2s;

  section {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  /* max-width: 300px; */
  @media (min-width: 700px) {
    max-width: 300px;
  }

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }

  /* @media (min-width: 610px) { */
    ${ props => props.animacao &&
      css`
        animation: ${moverParaCarrinho} 1s both;
      `
    }
  /* } */

`;

export const ProdutoImagem = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;

  min-height: 250px;
  max-height: 250px;
`;

export const ProdutoTitulo = styled.h3<CoresProps>`
  margin: 8px 0;
  padding: 0;
  border: none;
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
  color: ${props => props.cores.texto};
`;

export const ProdutoQtdeEPreco = styled.div<CoresProps>`
  display: flex;
  align-items: center;

  svg {
    margin-top: 4px;
    height: 28px;
    width: 28px;
    color: ${props => props.cores.textoClaro};

    transition: color 0.2s;
    &:hover {
      color: ${props => props.cores.textoClaroShade};
    }
  }

  button {
    background: transparent;
    border: none;
  }

  button:first {
    margin-left: 8px;
  }

  input {
    width: 40px;
    height: 36px;
    border: 1px solid ${props => props.cores.textoClaro};
    border-radius: 8px;
    font-size: 16px;
    color: ${props => props.cores.texto};
    font-weight: 700;

    text-align: center;
  }

  /* Oculta as setas do input number para todos os navegadores */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }


  span {
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    margin-left: auto;
    margin-right: 8px;
    /* margin-top: 8px; */
    color: ${props => props.cores.texto}
  }
`;

export const ProdutoBotaoComprar = styled.div<CoresProps>`
  width: 100%;
  height: 40px;
  button {
    background: ${props => props.cores.secundaria};
    border-radius: 0 0 8px 8px;
    border: none;
    width: 100%;
    margin-bottom: 0.2px;
    margin-top: 10px; /* somando a margem top com o height tem que dar o height da div superior */
    height: 30px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 16px;
      }
    }

  }
`;

// export const ProdutoPreco = styled.span`
//   font-size: 1.2rem;
//   font-weight: bold;
//   text-align: center;
//   margin-left: auto;
//   margin-right: 8px;
//   margin-top: 8px;
// `;

export const Footer = styled.div<CoresProps>`
  display: flex;
  min-height: 55px;
  background-color: ${props => props.cores.primaria};
  align-items: center;
  flex-direction: column;

  > div {
    display: flex;
    margin: 0 auto;
    margin-top: 4px;
    align-items: center;
  }

  span {
    color: #f4ede8;
    font-size: 14px;
  }

  strong {
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: #f4ede8;
  }

  img {
    height: 32px;
    width: 40px;
    margin-left: 3px;
  }

  section {
    margin-top: -4px;
    span {
      color: #f4ede8;
      font-size: 12px;
    }

  }

`;
