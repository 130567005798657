import React, { createContext, useContext, useCallback } from 'react';

import localforage from 'localforage';

interface IndexedDBContextData {
  gravarIndexedDB(chave: string, valor: any): void;
  lerIndexedDB<T extends unknown>(chave: string): Promise<T | null>;
  excluirIndexedDB(chave: string): void;
}

const IndexedDBContext = createContext<IndexedDBContextData>({} as IndexedDBContextData);

const nomeApp: string = '@CatalogoSizex';

const IndexedDBProvider: React.FC = ({ children }) => {

  const gravarIndexedDB = useCallback((chave: string, valor: any): void => {
    localforage.setItem(`${nomeApp}:${chave}`, valor);
  }, []);

  const lerIndexedDB = useCallback(<T extends unknown>(chave: string): Promise<T | null> =>
    localforage.getItem<T>(`${nomeApp}:${chave}`)
      .then((retorno) => { return retorno; })
      .catch((_) => { return null; })
  , []);

  const excluirIndexedDB = useCallback((chave: string): void => {
    localforage.removeItem(`${nomeApp}:${chave}`);
  }, []);

  return (
    <IndexedDBContext.Provider value={{ gravarIndexedDB, lerIndexedDB, excluirIndexedDB }}>
      {children}
    </IndexedDBContext.Provider>
  );
}

function useIndexedDB(): IndexedDBContextData {
  const context = useContext(IndexedDBContext);

  if (!context) {
    throw new Error('useIndexedDB deve ser usado dentro de um IndexedDBProvider');
  }

  return context;
}

export { IndexedDBProvider, useIndexedDB };
