import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  img {
    margin: 20vh auto 6vh;
    width: 240px;
    height: 250px;

    @media (max-width: 610px) {
      width: 150px;
      height: 160px;
    }
  }

  h1 {
    font-size: 52px;
    font-weight: bold;

    @media (max-width: 1150px) {
      font-size: 36px;
    }

    @media (max-width: 610px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 30px;
    font-size: 28px;
    margin-bottom: 50px;

    @media (max-width: 1150px) {
      font-size: 20px;
    }

    @media (max-width: 610px) {
      font-size: 12px;
    }
  }

  /* span {
    margin-top: 8px;
    font-size: 24px;
  } */
`;
