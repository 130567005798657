import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  FiPower,
  FiSearch,
  FiUser,
  FiShoppingCart,
  FiChevronLeft,
  FiChevronRight,
  FiList,
} from 'react-icons/fi';

import api from '../../services/api';

import {
  Container,
  CirculoCarregandoDiv,
  CirculoCarregando,
  Header,
  HeaderContent,
  Buscar,
  Profile,
  CarrinhoCompras,
  CarrinhoComprasConteudo,
  BuscarMobile,
  BannerContainer,
  // GruposContainer,
  TagContainerSticky,
  TagContainer,
  TagList,
  TagItem,
  Content,
  DescricaoBusca,
  BuscaSemResultados,
  GaleriaProdutos,
  ProdutoBox,
  ProdutoAnimatedBox,
  ProdutoImagem,
  ProdutoTitulo,
  ProdutoQtdeEPreco,
  ProdutoBotaoComprar,
  Footer,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';
import defaultAvatar from '../../assets/default-avatar.png';
import whatsIcone from '../../assets/whatsapp.png';
import produtoSemImagemImg from '../../assets/produto-sem-imagem.png';

import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { useConfigs } from '../../context/ConfigsContext';
import { useIndexedDB } from '../../context/IndexedDBContext';

interface Produto {
  id: string;
  descricao: string;
  preco: number;
  imagem_url: string;
  grupo: string;
  qtdeComprar: number;
  animacao: boolean;
}

interface Grupo {
  grupo: string;
  marcado: boolean;
}

const Home: React.FC = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();
  const toast = useToast();
  const { cores, lerConfig } = useConfigs();
  const { gravarIndexedDB, lerIndexedDB } = useIndexedDB();

  const tagListRef = useRef<HTMLUListElement>(null);
  const inputBuscarMobile = useRef<HTMLInputElement>(null);

  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(undefined);
  const [banner1Dinamico, setBanner1Dinamico] = useState<string | undefined>(undefined);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [carrinho, setCarrinho] = useState<Produto[]>([]);
  const [qtdeCarrinho, setQtdeCarrinho] = useState<number>(0);
  const [animacaoCarrinho, setAnimacaoCarrinho] = useState<boolean>(false);
  const [grupos, setGrupos] = useState<Grupo[]>([]);
  const [textoBuscar, setTextoBuscar] = useState<string>('');
  const [textoBuscarMobile, setTextoBuscarMobile] = useState<string>('');
  const [pesquisaRealizada, setPesquisaRealizada] = useState<string>('');
  const [pesquisaComResultados, setPesquisaComResultados] = useState(true);
  const [carregarComDelay, setCarregarComDelay] = useState(false);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const retornaProdutos = useCallback((lstProdutos: Produto[]): Produto[] => {
    return lstProdutos.map((item: Produto) => {
      return {
        id: item.id,
        descricao: item.descricao,
        preco: item.preco,
        imagem_url: item.imagem_url,
        grupo: item.grupo,
        qtdeComprar: 1,
        animacao: false,
      }
    });
  }, []);

  useEffect(() => {
    api.get<string[]>('/produtos/grupos').then((response) => {
      const gruposTemp: Grupo[] = response.data.map(item => {
        return { grupo: item, marcado: false };
      });
      setGrupos([{
        grupo: 'DESTAQUES',
        marcado: true,
       }, ...gruposTemp]);
    }).catch((err) => {
      if (String(err.message).indexOf('status code 401') > 0) {
        signOut();
      }
    });

    // IMAGENS DINÂMICAS
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configLogo
      } }).then(response => {
        setLogoDinamico(response.data);
      });
    } else {
      configLogo = undefined;
    }
    let configBanner: string | undefined = lerConfig('BANNER1');
    if (configBanner && configBanner !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configBanner
      } }).then(response => {
        setBanner1Dinamico(response.data);
      });
    } else {
      configBanner = undefined;
    }

    api.get<Produto[]>('/produtos/destaques').then((response) => {
      setProdutos(retornaProdutos(response.data));
    });

    // excluirIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`);
    lerIndexedDB<Produto[]>(`${user.empresa.url_frontend}:carrinho-compras`)
      .then((produtosIndexedDB) => {
        if (produtosIndexedDB == null) {
          setCarrinho([]);
          setQtdeCarrinho(0);
        } else {
          setCarrinho(produtosIndexedDB);
          setQtdeCarrinho(produtosIndexedDB.length);
        }
        // console.log(produtosIndexedDB);
      });

    // Delay pra dar tempo de carregar as cores do sistema quando não utiliza login
    setTimeout(() => {
      setCarregarComDelay(true);
    }, 1000);

  }, [signOut, retornaProdutos, lerConfig, lerIndexedDB, user.empresa.url_frontend]);

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const abrirVendas = useCallback(() => {
    history.push('/vendas');
  }, [history]);

  // Função para lidar com o arrastar e soltar
  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!tagListRef.current) return;

    const { current: tagList } = tagListRef;
    const startX = e.pageX - tagList.offsetLeft;
    const scrollLeft = tagList.scrollLeft;

    const onMouseMove = (e: MouseEvent) => {
      const x = e.pageX - tagList.offsetLeft;
      const scroll = x - startX;
      tagList.scrollLeft = scrollLeft - scroll;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const buscar = useCallback(async() => {
    setPesquisaComResultados(true);
    setPesquisaRealizada('');

    // let conteudoBuscar = inputBuscar.current?.value;
    let conteudoBuscar = textoBuscar;
    if (conteudoBuscar === '') {
      conteudoBuscar = textoBuscarMobile;
      if (conteudoBuscar === '') {
        toast.addToast({
          title: 'Falha na pesquisa',
          description: 'Digite algo a ser pesquisado'
        });
        return;
      }
    }
    conteudoBuscar = String(conteudoBuscar).toUpperCase();

    setProdutos([]);

    const resposta = await api.get<Produto[]>('/produtos/por-descricao', {
      params: {
        descricao: conteudoBuscar
      }
    });

    const gruposDesmarcados: Grupo[] = grupos.map((grupo) => {
      return {
        grupo: grupo.grupo,
        marcado: false
      }
    });

    setGrupos(gruposDesmarcados);
    setProdutos(retornaProdutos(resposta.data));
    setPesquisaRealizada(conteudoBuscar);
    setTextoBuscar('');
    setTextoBuscarMobile('');
    if (resposta.data.length === 0) {
      setPesquisaComResultados(false);
    }
  }, [toast, grupos, textoBuscar, textoBuscarMobile, retornaProdutos]);

  const aoPressionarTeclaBuscar = useCallback(async(e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await buscar();
    }
  }, [buscar]);

  const aoClicarTagItem = useCallback(async(grupo: string) => {
    setProdutos([]);
    setPesquisaRealizada('');
    setPesquisaComResultados(true);
    if (inputBuscarMobile.current) {
      inputBuscarMobile.current.blur();
    }

    let gruposTemp = grupos.filter(grupo => grupo.grupo !== '');
    gruposTemp = gruposTemp.map((grupoMap) => {
      if (grupoMap.grupo === grupo) {
        return {
          grupo: grupoMap.grupo,
          marcado: true,
        }
      }
      return {
        grupo: grupoMap.grupo,
        marcado: false,
      }
    });
    setGrupos(gruposTemp);

    if (grupo === 'DESTAQUES') {
      const response = await api.get<Produto[]>('/produtos/destaques');
      setProdutos(retornaProdutos(response.data));
    } else {
      const response = await api.get<Produto[]>('/produtos/por-grupo', {
        params: {
          grupo: grupo
        }
      });
      setProdutos(retornaProdutos(response.data));
    }
  }, [grupos, retornaProdutos]);

  const setProdutoQtde = useCallback((valor: string, produtoId: string) => {
    setProdutos(produtos.map((item) =>
      item.id === produtoId ? {...item, qtdeComprar: Number(valor)} : item )
    );
  }, [produtos]);

  const incrementarProdutoQtde = useCallback((valor: number, produtoId: string) => {
    setProdutos(produtos.map((item) =>
      item.id === produtoId ? {...item, qtdeComprar: (Number(valor) + 1)} : item )
    );
  }, [produtos]);

  const decrementarProdutoQtde = useCallback((valor: number, produtoId: string) => {
    if (Number(valor) > 1) {
      setProdutos(produtos.map((item) =>
        item.id === produtoId ? {...item, qtdeComprar: (Number(valor) - 1)} : item )
      );
    }
  }, [produtos]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const comprarProduto = useCallback((prodPar: Produto) => {
    // console.log(prodPar);
    prodPar.animacao = true;
    setAnimacaoCarrinho(true);
    let carrinhoTemp = [...carrinho];

    // checa se produto já está no carrinho
    const indiceProduto = carrinhoTemp.findIndex(prod => prod.id === prodPar.id);
    if (indiceProduto >= 0) {
      carrinhoTemp[indiceProduto].qtdeComprar += prodPar.qtdeComprar;
    } else {
      carrinhoTemp.push(prodPar);
    }

    // console.log(carrinho);
    // console.log(carrinhoTemp);

    setCarrinho(carrinhoTemp);
    setQtdeCarrinho(carrinhoTemp.length);
    gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`, carrinhoTemp);
    setTimeout(() => {
      prodPar.animacao = false;
      setAnimacaoCarrinho(false);
    }, 1000);
  }, [carrinho, gravarIndexedDB, user.empresa.url_frontend]);

  const aoClicarCarrinho = useCallback(() => {
    if (qtdeCarrinho > 0) {
      history.push('/carrinho');
    }
  }, [qtdeCarrinho, history]);

  return ( carregarComDelay ?
    <Container>
      <Header cores={cores} >
        <HeaderContent cores={cores}>
          <img onClick={scrollToTop} src={logoDinamico ? logoDinamico : logoImg} alt="Logo"/>

          <Buscar cores={cores}>
            <input
              type="text"
              placeholder="Pesquisar"
              onKeyPress={aoPressionarTeclaBuscar}
              value={textoBuscar}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextoBuscar(e.target.value)}
            />
            <button type='button' onClick={buscar} >
                <FiSearch size={28}/>
            </button>
          </Buscar>

          { user.empresa.utiliza_login === 'S' &&
            <Profile cores={cores}>
              <img src={!user.avatar_url ? defaultAvatar : user.avatar_url} alt={user.name} />
              <ul>
                <li><button type="button" onClick={abrirVendas}><FiList /><span>Vendas</span></button></li>
                <li><button type="button" onClick={abrirProfile}><FiUser /><span>Perfil</span></button></li>
                <li><button type="button" onClick={signOut}><FiPower /><span>Sair</span></button></li>
              </ul>
              <div>
                <span>Bem vindo,</span>
                <strong>{user.name}</strong>
              </div>
            </Profile>
          }

          <CarrinhoCompras utiliza_login={user.empresa.utiliza_login} onClick={aoClicarCarrinho} >
            <CarrinhoComprasConteudo utiliza_login={user.empresa.utiliza_login} cores={cores} animacao={animacaoCarrinho}>
              <FiShoppingCart />
              {(qtdeCarrinho > 0) && <span>{qtdeCarrinho}</span>}
            </CarrinhoComprasConteudo>
          </CarrinhoCompras>
        </HeaderContent>
      </Header>

      <BuscarMobile cores={cores}>
        <input
          type="text"
          placeholder="Pesquisar"
          onKeyPress={aoPressionarTeclaBuscar}
          value={textoBuscarMobile}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextoBuscarMobile(e.target.value)}
          ref={inputBuscarMobile}
        />
        <button type='button' onClick={buscar}><FiSearch size={28} /></button>
      </BuscarMobile>

      <BannerContainer banner1={banner1Dinamico} />

      {/* <GruposContainer cores={cores}>
          <ul>
            <li>
              <button><FiMenu/><span>Todos os Grupos</span></button>
              <section>
                <ul>
                  <div><button type="button"><span>SMARTPHONES</span></button></div>
                  <div><button type="button"><span>TABLETS</span></button></div>
                  <div><button type="button"><span>TELEVISORES</span></button></div>
                  <div><button type="button"><span>NOTEBOOKS</span></button></div>
                  <div><button type="button"><span>DESKTOPS</span></button></div>
                  <div><button type="button"><span>TECLADOS</span></button></div>
                  <div><button type="button"><span>MOUSES</span></button></div>
                  <div><button type="button"><span>FONES DE OUVIDO</span></button></div>
                  <div><button type="button"><span>SOUNDBAR</span></button></div>
                  <div><button type="button"><span>MONITORES</span></button></div>
                  <div><button type="button"><span>IMPRESSORAS</span></button></div>
                  <div><button type="button"><span>IMPRESSORAS</span></button></div>
                </ul>
              </section>
            </li>
            <li><button>ROUPAS</button></li>
            <li><button>TÊNIS</button></li>
            <li><button>SAPATOS</button></li>
            <li><button>BRINQUEDOS</button></li>
          </ul>
      </GruposContainer> */}

      <TagContainerSticky>
        <TagContainer>
          <TagList ref={tagListRef} onMouseDown={handleMouseDown}>
            {grupos.map((grupo) => (
              <TagItem
                onClick={() => aoClicarTagItem(grupo.grupo)}
                marcado={grupo.marcado}
                cores={cores}
                key={grupo.grupo}>
                  {grupo.grupo}
              </TagItem>
            ))}
          </TagList>
        </TagContainer>
      </TagContainerSticky>

      <Content>
        <DescricaoBusca textoBusca={pesquisaRealizada} cores={cores}>RESULTADOS PARA "{pesquisaRealizada}":</DescricaoBusca>
        { !pesquisaComResultados && <BuscaSemResultados cores={cores}>
          <h3>NÃO FOI POSSÍVEL ENCONTRAR RESULTADOS PARA O TERMO PROCURADO</h3>
          <p>Verifique se você digitou as palavras corretamente ou tente novamente a busca.</p>
        </BuscaSemResultados> }
        <GaleriaProdutos>
          {produtos.map((produto) => (
            <ProdutoBox key={produto.id} >
              <ProdutoAnimatedBox animacao={produto.animacao}>
                <section>
                  <ProdutoImagem src={produto.imagem_url ? produto.imagem_url : produtoSemImagemImg} alt={produto.descricao} />
                  <ProdutoTitulo cores={cores}>{produto.descricao}</ProdutoTitulo>
                  <ProdutoQtdeEPreco cores={cores}>
                    <button
                      type="button"
                      onClick={() => decrementarProdutoQtde(produto.qtdeComprar, produto.id)}
                    >
                      <FiChevronLeft />
                    </button>
                    <input
                      type="Number"
                      step="1"
                      value={produto.qtdeComprar}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setProdutoQtde(e.target.value, produto.id)}
                    />
                    <button
                      type="button"
                      onClick={() => incrementarProdutoQtde(produto.qtdeComprar, produto.id)}
                    >
                      <FiChevronRight />
                    </button>
                    <span>{formatter.format(produto.preco)}</span>
                  </ProdutoQtdeEPreco>
                </section>
                <ProdutoBotaoComprar cores={cores}>
                  <button
                      type="button"
                      onClick={() => comprarProduto(produto)}
                    >
                    <div>
                      <FiShoppingCart />
                      <span>COMPRAR</span>
                    </div>
                  </button>
                </ProdutoBotaoComprar>
              </ProdutoAnimatedBox>
            </ProdutoBox>
          ))}
        </GaleriaProdutos>
      </Content>

      <Footer cores={cores}>
        <div>
          <span><strong>{user.empresa.nome_fantasia}</strong></span>
          <img src={whatsIcone} alt="WhatsApp Ícone"/>
          {/* <a href="tel:+551735255830">(17) 3525-5830</a> */}
          <a href={user.empresa.whats_link}>{user.empresa.whats_formatado}</a>
        </div>
        <section>
          <span>© 2023 Copyright <a href="http://www.sizex.com.br" target="_blank" rel="noopener noreferrer">Sizex Sistemas</a></span>
        </section>
      </Footer>
    </Container> : <CirculoCarregandoDiv><CirculoCarregando cores={cores} /></CirculoCarregandoDiv>
  );
};

export default Home;
