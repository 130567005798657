import styled from 'styled-components';

import ICoresData from '../../context/ICoresData';

interface CoresProps {
  cores: ICoresData;
}

export const Container = styled.button<CoresProps>`
  background: ${props => props.cores.primaria};
  height: 56px;
  border-radius: 10px;
  border: 0;
  /* padding só nas laterais */
  padding: 0 16px;
  color: #FFF;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
      background: ${props => props.cores.primariaShade};
  }
`;
