import styled, { keyframes, css } from 'styled-components';
// import { shade } from 'polished';
import { TextField, CircularProgress } from '@material-ui/core';

import ICoresData from '../../context/ICoresData';

interface CoresProps {
  cores: ICoresData;
}

interface ProdutoBoxProps {
  animacao: boolean;
}

const animacaoExcluir = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

export const Container = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.cores.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.cores.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.cores.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.cores.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.cores.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.cores.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.cores.textoClaro};
    }
  }
`;

export const CirculoCarregandoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const CirculoCarregando = styled(CircularProgress)<CoresProps>`
  color: #E6E600 !important;
  color: ${props => props.cores.secundaria} !important;

  width: 80px !important;
  height: 80px !important;
  @media (max-width: 610px) {
    width: 40px !important;
    height: 40px !important;
  }
`;

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Header = styled.header<CoresProps>`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 16px 0;
  background: ${props => props.cores.primaria};
  /* width: auto; */
  flex: 1;

  animation: ${surgirDeCima} 1s;

  height: 110px;
  @media (max-width: 610px) {
    height: 80px;
  }
`;

export const HeaderContent = styled.div<CoresProps>`
  max-width: 1120px;
  /* dá uma alinhada no centro no header content */
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin: 0 24px;
  }

  /* estiliza a primeira imagem: a logo */
  a {
    img {
      height: 80px;
      @media (max-width: 610px) {
        height: 56px;
      }
      cursor: pointer;
    }
  }

`;

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const Profile = styled.div<CoresProps>`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: relative;

  @media (max-width: 610px) {
    margin-left: auto;
  }

  strong {
    font-weight: 700;
    color: ${props => props.cores.secundaria};

    /* ocupa todo o espaco possivel na esquerda com margem */
    margin-left: auto;

    text-decoration: none;
    color: ${props => props.cores.secundaria};
    font-size: 16px;
  }

  img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;

    @media (max-width: 610px) {
      width: 44px;
      height: 44px;
    }
  }

  ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFF;
    border-radius: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    animation: ${fadeIn} 0.5s;
    width: 160px;
    z-index: 1;

    li {
      border-bottom: 1px solid #eee;

      display: block;
      button {
        padding: 10px 15px;
        background: transparent;
        border: 0;
        width: 100%;
        display: flex;
        align-items: center;
        svg {
          color: ${props => props.cores.secundaria};
          height: 24px;
          width: 24px;
        }
        span {
          color: ${props => props.cores.texto};
          font-size: 20px;
          margin-left: 8px;
          transition: color 0.2s;
        }

        &:hover {
          span {
            color: ${props => props.cores.secundaria}
          }
        }
      }
    }

    // último item do menu sem borda
    li:last-child {
      border-bottom: none;
    }

  }

  // mostrar menu no hover
  &:hover {
    ul {
      display: block;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    @media (max-width: 610px) {
      font-size: 14px;
      margin-left: 8px;
      line-height: 20px;
    }

    span {
      color: #f4ede8;

      @media (max-width: 610px) {
        font-size: 14px;
      }
    }

    strong {
      @media (max-width: 610px) {
        font-size: 14px;
      }
    }

  }
`;

export const Content = styled.main`
  min-height: 600px;

  /* @media (max-width: 610px) {
    min-height: 600px;
  } */

  margin-top: 110px;
  @media (max-width: 610px) {
    margin-top: 80px;
  }
  /* margin-left: auto; */
  /* margin-right: auto; */
`;

export const GaleriaProdutos = styled.div`
  margin: 2rem;
  margin-top: 20px auto;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  /* @media (min-width: 610px) {
    min-width: 700px;
  } */

  /* Para evitar que a animação gere uma barra de rolagem */
  /* overflow: hidden; */
`;

export const ProdutoBox = styled.div`
  animation: ${fadeIn} 1s;
  /* min-height: 250px; */
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
`;

export const ProdutoAnimatedBox = styled.div<ProdutoBoxProps>`
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  height: 100%;

  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.2s;

  /* max-width: 300px; */
  /* @media (min-width: 700px) {
    max-width: 300px;
  } */

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }

  section {
    margin: 12px;
    width: 350px;
  }

  ${ props => props.animacao &&
    css`
      animation: ${animacaoExcluir} 0.5s both;
    `
  }
`;

export const ProdutoImagem = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 8px;
`;

export const ProdutoTitulo = styled.h3<CoresProps>`
  margin: 8px 0;
  padding: 0;
  border: none;
  font-size: 16px;
  /* font-weight: bold; */
  text-align: left;
  font-weight: 700;
  color: ${props => props.cores.texto};

  @media (max-width: 610px) {
    font-size: 13px;
  }
`;

export const ProdutoQtde = styled.div<CoresProps>`
  display: flex;
  align-items: center;
  margin-left: -4px;

  p {
    font-size: 16px;
  }

  svg {
    margin-top: 4px;
    height: 20px;
    width: 20px;
    color: ${props => props.cores.textoClaro};

    transition: color 0.2s;
    &:hover {
      color: ${props => props.cores.textoClaroShade};
    }

    @media (max-width: 610px) {
      width: 16px;
      height: 16px;
    }
  }

  button {
    background: transparent;
    border: none;
  }

  /* button:first {
    margin-left: 8px;
  } */

  input {
    width: 32px;
    height: 26px;
    border: 1px solid ${props => props.cores.textoClaro};
    border-radius: 8px;
    font-size: 14px;
    color: ${props => props.cores.texto};
    font-weight: 700;

    text-align: center;

    @media (max-width: 610px) {
      width: 30px;
      height: 24px;
      font-size: 12px;
    }
  }

  /* Oculta as setas do input number para todos os navegadores */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ProdutoPreco = styled.div<CoresProps>`
  margin-top: 6px;

  span {
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    /* margin-top: 8px; */
    color: ${props => props.cores.texto};

    @media (max-width: 610px) {
      font-size: 12px;
    }

  }
`;

export const ProdutoExcluir = styled.div<CoresProps>`
  margin-top: 18px;
  margin-left: auto;

  svg {
    font-size: 24px;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: ${props => props.cores.secundaria};
    }

    @media (max-width: 610px) {
      font-size: 20px;
    }
  }
`;

export const TotalGaleria = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;

  hr {
    border: 3px solid ${props => props.cores.textoClaro};
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    margin-top: 12px;
  }

  div {
    display: flex;
    margin-right: 8px;
    font-size: 20px;
    @media (max-width: 610px) {
      font-size: 16px;
    }
    /* text-shadow: 0.1px 0.1px 0.1px #000; */

    span {
      font-weight: 700;
      margin-left: 8px;
      flex: 1;
    }

    strong {
      font-weight: 700;
    }
  }
`;

export const BoxDadosClienteSimples = styled.div`
  animation: ${fadeIn} 1s;
  margin-top: 40px;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  /* background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  /* transition: box-shadow 0.2s; */

  /* &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  } */

  padding: 0 8px;

  div {
    display: flex;
  }
`;

export const InputNomeClienteSimples = styled(TextField)`
  width: 100%;
`;

export const InputFoneClienteSimples = styled(TextField)`
  width: 49%;
`;

export const InputEmailClienteSimples = styled(TextField)`
  margin-left: auto !important;
  width: 49%;

  /* letra minúscula */
  & .MuiOutlinedInput-input {
    text-transform: lowercase !important;
  }
`;

export const InputObsClienteSimples = styled(TextField)`
  width: 100%;
`;

export const BotaoFinalizar = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0 8px;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 30px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;

export const BoxDadosClienteLogin = styled.div`
  animation: ${fadeIn} 1s;
  margin-top: 40px;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  /* background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  /* transition: box-shadow 0.2s; */

  /* &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  } */

  padding: 0 8px;

  div {
    display: flex;
  }
`;

export const AreaClienteLogin = styled.div`
  display: flex;
`;

export const InputNomeClienteLogin = styled(TextField)`
  flex: 1;
`;

export const BotaoPesquisarClientes = styled.div<CoresProps>`
  animation: ${fadeIn} 1s;
  width: 80px; //tava 100%
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0 8px;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;
    /* width: 100%; */

    height: 50px;
    color: #FFF;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
      }
    }

  }
`;

export const InputObsClienteLogin = styled(TextField)`
  width: 100%;
`;
